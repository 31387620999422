import './Modal.scss';

import { Capacitor } from '@capacitor/core';
import { X } from '@phosphor-icons/react';
import { KeyboardKey } from '@travelwin/core';
import classNames from 'classnames';
import { MouseEvent, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { RemoveScroll } from 'react-remove-scroll';

interface Props {
  children: ReactNode;
  on?: boolean;
  toggle: () => void;
  title?: ReactNode;
  closeOnBackgroundClick?: boolean;
  closeOnEscapeClick?: boolean;
  hideCloseButton?: boolean;
  fullScreen?: boolean;
  headerComponent?: ReactNode;
  fixed?: boolean;
}

const Modal = ({
  children,
  on,
  toggle,
  title,
  closeOnBackgroundClick,
  closeOnEscapeClick,
  hideCloseButton,
  fullScreen,
  /** used for inserting an additioanl component into the header e.g. a button */
  headerComponent,
  fixed = false,
}: Props) => {
  const className = 'c-EcomModal';
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null);
  const [container, setContainer] = useState<HTMLElement | null>(null);

  useEffect(() => {
    function handleEscapeKeyDown(event: KeyboardEvent) {
      if (event.key === KeyboardKey.Escape) {
        toggle();
      }
    }

    if (closeOnEscapeClick) {
      document.addEventListener('keydown', handleEscapeKeyDown);
    }

    return () => {
      if (closeOnEscapeClick) {
        document.removeEventListener('keydown', handleEscapeKeyDown);
      }
    };
  }, [closeOnEscapeClick, toggle]);

  useEffect(() => {
    const modalEl = document.getElementById('modal');

    if (modalEl) {
      setContainer(modalEl);
    }
  }, []);

  const headerContainer = () => {
    if (title || !hideCloseButton) {
      return (
        <div className={`${className}__header`}>
          {!hideCloseButton && (
            <button
              type="button"
              className={`${className}__close-button`}
              data-testid="button-close-modal"
              onClick={toggle}
            >
              <X weight="bold" size={32} />
            </button>
          )}
          {title && (
            <h3 className={classNames(`${className}__title`)}>{title}</h3>
          )}
          {!hideCloseButton && <div className={`${className}__spacer`} />}
          {headerComponent && (
            <div className={`${className}__header-component`}>
              {headerComponent}
            </div>
          )}
        </div>
      );
    }
  };

  const checkModalClose = (event: MouseEvent<HTMLDivElement>) => {
    if (wrapperRef && !wrapperRef.contains(event.target as Node)) toggle();
  };

  return on && container
    ? ReactDOM.createPortal(
        <RemoveScroll>
          <div
            className={classNames(className, {
              [`${className}--full-screen`]: fullScreen,
              [`${className}--native`]: Capacitor.isNativePlatform(),
              [`${className}--fixed`]: fixed,
            })}
            onClick={(event) => {
              // prevents pointer events if the modal is inside another clickable element
              event.stopPropagation();
              closeOnBackgroundClick && checkModalClose(event);
            }}
          >
            <div
              className={classNames(`${className}__wrapper`, {
                [`${className}__wrapper--full-screen`]: fullScreen,
                [`${className}__wrapper--native`]: Capacitor.isNativePlatform(),
                [`${className}__wrapper--fixed`]: fixed,
              })}
            >
              <div
                className={classNames(`${className}__inner`, {
                  [`${className}__inner--full-screen`]: fullScreen,
                  [`${className}__inner--full-screen-native`]:
                    fullScreen && Capacitor.isNativePlatform(),
                  [`${className}__inner--fixed`]: fixed,
                })}
                ref={(current) => setWrapperRef(current)}
              >
                {headerContainer()}
                <div className={`${className}__content`}>{children}</div>
              </div>
            </div>
          </div>
        </RemoveScroll>,
        container,
      )
    : null;
};

export default Modal;
